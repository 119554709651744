<template>
  <div class="home">
    <div class="blue-circle top"></div>
    <div class="body">
      <div class="logo">
        <img class="img" src="@/assets/images/logo@2x.png" alt="" />
        <div class="t1">委外家访移动平台</div>
        <div class="t2">Outsourcing home visit mobile platform</div>
      </div>
      <div class="line l1"></div>
      <div class="line l2"></div>
      <div class="content">
        <div
          v-if="userBaseInfo.resOperatorDTO.isAdmin"
          class="item"
          @click="routerPush({ name: 'ListCase', query: { orderStatus: '0' } })"
        >
          <div class="left">
            <div class="title">待派单案件</div>
            <div class="condition danger">请尽快处理案件</div>
          </div>
          <div class="right">
            <span>{{ indexCount.pendingAssignmentNum }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div
          class="item"
          @click="routerPush({ name: 'ListCase', query: { orderStatus: '2' } })"
        >
          <div class="left">
            <div class="title">待接单案件</div>
            <div class="condition danger">请尽快接单案件</div>
          </div>
          <div class="right">
            <span>{{ indexCount.pendingOrderAcceptanceNum }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div
          class="item"
          @click="routerPush({ name: 'List', query: { statusEq: 'PROCESSING' } })"
        >
          <div class="left">
            <div class="title">处理中的案件</div>
            <div class="condition danger">请尽快处理案件</div>
          </div>
          <div class="right">
            <span>{{ indexCount.processingNum }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div
          class="item"
          @click="routerPush({ name: 'List', query: { statusEq: 'APPROVALING' } })"
        >
          <div class="left">
            <div class="title">待审核案件</div>
            <div class="condition">正在审核中案件</div>
          </div>
          <div class="right">
            <span>{{ indexCount.approvalNum }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="item" @click="routerPush({ name: 'ListFeedBack' })">
          <div class="left">
            <div class="title">待反馈案件</div>
            <div class="condition warning">请及时反馈案件进度</div>
          </div>
          <div class="right">
            <span>{{ indexCount.unFeedbackNum }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
        <div class="item" @click="routerPush({ name: 'ListExpire' })">
          <div class="left">
            <div class="title">即将到期案件</div>
            <div class="condition">案件即将到期</div>
          </div>
          <div class="right">
            <span>{{ indexCount.expireNum }}</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <TabBarNav></TabBarNav>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Tabbar, TabbarItem, Toast } from "vant";
Vue.use(Tabbar);
Vue.use(TabbarItem);

import Api from "@/api/caseHandle";
import ApiUser from "@/api/user";

export default {
  data() {
    return {
      userBaseInfo: {
        resOperatorDTO: {}
      },
      indexCount: {
        pendingAssignmentNum: 0,
        pendingOrderAcceptanceNum: 0,
        approvalNum: 0,
        expireNum: 0,
        processingNum: 0,
        unFeedbackNum: 0,
      },
    };
  },
  methods: {
    routerPush() {
      this.$router.push(...arguments);
    },

    async getOrgIndexCount() {
      try {
        const res = await Api.getOrgIndexCount();
        Object.assign(this.indexCount, res.data);
      } catch (error) {
        Toast.fail(error);
      }
    },
  },
  async mounted() {
    const userBaseInfo = await ApiUser.baseInfo();
    userBaseInfo.success && (this.userBaseInfo = userBaseInfo.data);
    this.getOrgIndexCount();
  },
};
</script>
<style lang="less" scoped>
.blue-circle {
  width: 118px;
  height: 10px;
  background: #3c86ff;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  &.top {
    border-radius: 0px 0px 100px 100px;
    top: 0px;
  }
}
.home {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  .body {
    flex: 1;
    display: flex;
    flex-direction: column;

    .logo {
      padding: 40px 32px;
      .img {
        width: 49px;
        display: block;
      }
      .t1 {
        margin-top: 20px;
        font-weight: Medium;
        font-size: 20px;
        color: #212528;
        letter-spacing: 0;
      }
      .t2 {
        margin-top: 3px;
        font-weight: Medium;
        font-size: 9px;
        color: #9a9ea1;
        letter-spacing: 0;
      }
    }

    .line {
      height: 1px;
      opacity: 0.11;
      &.l1 {
        background: #787b84;
      }
      &.l2 {
        background: #ffffff;
      }
    }

    .content {
      flex: 1;
      overflow-y: auto;
      padding: 10px 12px;
      padding-bottom: 50px;

      .item {
        margin-top: 15px;
        background: #ffffff;
        border-radius: 6px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 22px 20px 22px 24px;
        .left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title {
            font-weight: Medium;
            font-size: 16px;
            color: #393939;
            letter-spacing: 0;
          }
          .condition {
            margin-top: 8px;
            font-weight: Regular;
            font-size: 12px;
            color: #a0a5b5;
            letter-spacing: 0;

            &.warning {
              color: #da9456;
            }
            &.danger {
              color: #ff6363;
            }
          }
        }

        .right {
          display: flex;
          align-items: center;
          span {
            font-weight: Medium;
            font-size: 24px;
            color: #000000;
            letter-spacing: 0;
            text-align: right;
          }
          .van-icon {
            margin-left: 10px;
            font-size: 20px;
            font-weight: bold;
            color: #d3d6dc;
          }
        }

        &::before {
          position: absolute;
          content: " ";
          top: 0px;
          left: 18px;
          width: 48px;
          height: 4px;
          background: #3c86ff;
          border-radius: 0 0 100px 100px;
        }
      }
    }
  }
  .footer {
    height: 50px;
  }
}
</style>